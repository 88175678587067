import Layout from "./common/layout"
import Head from "./common/Head"
import AceYourGmv from "pages/company/AceYourGmv"

const AceYourGmvPage = () => {
  return (
    <>
      <Head
        title={"Ace Your GMV"}
        description={"Ace Your GMV"}
        showCaptcha={true}
      />
      <Layout displayApplyBtn={false}>
        <AceYourGmv />
      </Layout>
    </>
  )
}

export default AceYourGmvPage

import { useState } from "react"
import "./assets/scss/index.scss"
import AceGmv1 from "./assets/images/ace-gmv-1.png"
import AceGmv2 from "./assets/images/ace-gmv-2.png"
import AceGmv3 from "./assets/images/ace-gmv-3.png"
import AceGmv4 from "./assets/images/ace-gmv-4.png"
import AceGmv5 from "./assets/images/ace-gmv-5.png"
import AceGmv4Form from "./assets/images/ace-gmv-4-form.png"
import AceGmv4Btn from "./assets/images/ace-gmv-4-btn.png"
import { validateAndSendEmail } from "helpers/feedback"

const emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$"

function AceYourGmv() {
  const [contactData, setContactData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const contactInputs = [
    {
      name: "your_name",
      label: "Họ và tên",
      id: "name-input",
    },
    {
      name: "phone_number",
      label: "Số điện thoại",
      id: "phone-input",
      inputProps: {
        type: "text",
        onInput: e => {
          e.target.value = e.target.value.replace(/[^0-9]/g, "")
        },
      },
    },
    {
      name: "email",
      label: "Email công ty",
      id: "email-input",
      inputProps: {
        type: "email",
        pattern: emailPattern,
      },
    },
    {
      name: "company",
      label: "Tên công ty",
      id: "company-input",
    },
    {
      name: "position",
      label: "Chức danh",
      id: "position-input",
    },
    {
      name: "industry",
      label: "Lĩnh vực hoạt động",
      id: "industry-input",
    },
    {
      name: "enquiry_information",
      label: "Đặt câu hỏi cho các speaker",
      id: "enquiry-input",
      type: "textarea",
    },
  ]

  const onSubmitContact = e => {
    if (
      !contactData.your_name ||
      !contactData.phone_number ||
      !contactData.email ||
      !contactData.company ||
      !contactData.position ||
      !contactData.industry ||
      !contactData.email?.match(emailPattern)
    ) {
      return
    }
    e.preventDefault()

    const params = {
      ...contactData,
      subject: `[${contactData.industry}][${contactData.position}] Ace Your GMV Contact Information`,
      type: "message_type",
    }

    setIsLoading(true)

    validateAndSendEmail(params).then(result => {
      if (result.success) {
        setContactData({})
        alert("Your message has been sent successfully!")
      } else {
        alert(result.message)
      }
    })

    setIsLoading(false)
  }

  return (
    <div className="app-landingpage base-width-landing">
      <div className="landingpage content-center">
        <a href="#contact">
          <img className="base-width-landing" src={AceGmv1} />
        </a>
      </div>
      <div className="landingpage content-center">
        <img className="base-width-landing" src={AceGmv2} />
      </div>
      <div className="landingpage content-center">
        <img className="base-width-landing" src={AceGmv3} />
      </div>
      <div id="contact" className="landingpage content-center relative">
        <img className="base-width-landing" src={AceGmv4} />
        <div className="ace-form-bg">
          <img className="form-img" src={AceGmv4Form} />
          <div className="ace-form-container">
            <form className="contact-form">
              <div className="form-header">
                <p>ĐĂNG KÝ THAM GIA SỰ KIỆN</p>
              </div>
              <div className="input-grid">
                {contactInputs.map(input => {
                  if (input.type === "textarea") {
                    return (
                      <div className="textarea-container">
                        <label className="contact-label" htmlFor={input.id}>
                          {input.label}
                        </label>
                        <textarea
                          id={input.id}
                          className="contact-textarea"
                          required={true}
                          disabled={isLoading}
                          onChange={e =>
                            setContactData({
                              ...contactData,
                              [input.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    )
                  }
                  return (
                    <div className="input-container">
                      <label className="contact-label" htmlFor={input.id}>
                        {input.label}
                      </label>
                      <input
                        id={input.id}
                        className="contact-input"
                        required={true}
                        disabled={isLoading}
                        onChange={e =>
                          setContactData({
                            ...contactData,
                            [input.name]: e.target.value,
                          })
                        }
                        {...input.inputProps}
                      />
                    </div>
                  )
                })}
              </div>
              <div className="action-container">
                <button
                  className="contact-submit-btn"
                  type="submit"
                  disabled={isLoading}
                  onClick={onSubmitContact}
                >
                  <img className="contact-submit-img" src={AceGmv4Btn} />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="landingpage content-center">
        <img className="base-width-landing" src={AceGmv5} />
      </div>
    </div>
  )
}

export default AceYourGmv

export function validateAndSendEmail(params) {
  return new Promise(res => {
    validateCaptcha().then(token => {
      // Here wait token generated
      if (token) {
        return res(submitSendEmail(token, params))
      }
    })
  })
}

function validateCaptcha() {
  return new Promise(res => {
    grecaptcha.ready(() => {
      grecaptcha
        .execute(`${process.env.GATSBY_RECAPTCHA_SITE_KEY}`, {
          action: "submit",
        })
        .then(token => {
          return res(token)
        })
    })
  })
}

async function submitSendEmail(token, params) {
  const data = JSON.stringify({ ...params, g_recaptcha_token: token })

  const url = `${process.env.GATSBY_OPOLLO_API_URL}/v1/feedback`
  try {
    let res = await fetch(url, {
      method: "POST",
      headers: new Headers({ "content-type": "application/json" }),
      body: data,
    })
    const response = await res.json()
    if (response.status === "OK") {
      return {
        success: true,
        message: response.data,
      }
    } else {
      return {
        success: false,
        message: response.data,
      }
    }
  } catch (err) {
    console.log(err)
  }
}
